<!--
@Author: laborde
@Date:   2022-04-20T09:17:08+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-07-12T13:33:25+02:00
-->

<template>
  <div>
    <div class="d-flex flex-wrap" v-if="articles">
      <div class="articleContenerClass" v-for="(article,i) in articles"  :key="article.uri">
        <div :class="{'articleClass': true }" v-if="article">
          <div class="d-flex flex-column h-100 mt-1">

            <div id="clickableZone" class="d-flex flex-column h-100 mb-4" @click="openArticle(article)">
              <b-img-lazy v-if="article.uri && errorLoadingImageList.indexOf(article.uri) == -1" id="articleImage" crossorigin="anonymous" :src="imageBaseURL + article.uri" @error.native="errorLoadingImage(article.uri)" fluid></b-img-lazy>
              <img v-if="errorLoadingImageList.indexOf(article.uri) > -1" id="articleNoImage" crossorigin="anonymous" :src="getNoImage(article.shortname_first_theme)" style="opacity:0.3"/>
              <div class="d-flex align-items-start">
                <div id="mediaName">{{ article.source_name }}</div>
                <div id="articleLanguage" class="ml-auto" v-if="article.metadata.language">{{ displayLanguage(article.metadata.language) }}</div>
              </div>

              <span class="text-justify" id="title">{{ article.title }}</span>

              <span class="text-justify" id="body">{{ article.body }}</span>

            </div>

            <div class="d-flex align-items-center">
              <div id="relativeTime">
                 {{ toStringDate(article.metadata.date) }}
              </div>
              <img v-if="article.isFav" class="ml-2" src="@/assets/img/png/ico_star_fill.png" title="Article in a followed theme" style="width:1em;height:1em;">
              <div class="d-flex align-items-center ml-auto">
                <div class="d-flex align-items-center" @click="saveArticle(article,i)" :title="$t('ph.save_for_later')" style="cursor:pointer;">
                  <img id="bookmark" v-if="!article.is_saved" class="mr-1" src="@/assets/img/svg/ico_bookmark_outlined.svg" style="height:1em;">
                  <img id="bookmark" v-if="article.is_saved" class="mr-1" src="@/assets/img/svg/ico_bookmark_fill.svg" style="height:1em;">
                </div>
                <div class="d-flex align-items-center" @click="current_article = article; showSocial = true" :title="$t('w.share')" style="cursor:pointer;">
                  <img class="mr-1" src="@/assets/img/svg/ico_share.svg" style="height:1em;">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <b-modal hide-header hide-footer size="md" v-model="showSocial">
      <b-button class="crossButton" @click="showSocial = false">✕</b-button>

      <h5 align="center" class="largeTitle mb-3">{{ $t('ph.share_via') }}</h5>
      <div v-if="current_article" class="d-flex flex-wrap align-items-center">

        <div id="networkButton" class="d-flex flex-column align-items-center mx-auto px-2" @click="copyURL(current_article)" style="cursor:pointer;">
          <img class="mx-auto mx-2 my-1" src="@/assets/img/svg/ico_link.svg" style="width:2.4em;">
          <span class="mx-auto">{{ $t('w.link') }}</span>
        </div>

        <ShareNetwork id="networkButton" class="d-flex flex-column mx-auto px-2" network="LinkedIn" :url="current_article.article_url" :title="current_article.title">
          <img class="mx-auto" src="@/assets/img/svg/ico_linkedin.svg" style="width:2.8em;" title="LinkedIn">
          <span class="mx-auto">LinkedIn</span>
        </ShareNetwork>
        <ShareNetwork id="networkButton" class="d-flex flex-column mx-auto px-2" network="Twitter" :url="current_article.article_url"  :title="current_article.title" hashtags="smartbeautyinsight">
          <img class="mx-auto" src="@/assets/img/svg/ico_twitter.svg" style="width:2.8em;" title="Twitter">
          <span class="mx-auto">Twitter</span>
        </ShareNetwork>
        <ShareNetwork id="networkButton" class="d-flex flex-column mx-auto px-2" network="facebook" :url="current_article.article_url"  :title="current_article.title" hashtags="smartbeautyinsight">
          <img class="mx-auto" src="@/assets/img/svg/ico_facebook.svg" style="width:2.8em;" title="Facebook">
          <span class="mx-auto">Facebook</span>
        </ShareNetwork>

        <div id="networkButton" class="smallScreen d-flex flex-column align-items-center mx-auto px-2" @click="shareToDevice(current_article.title, current_article.body, current_article.article_url)" style="cursor:pointer;">
          <img class="mx-auto mx-2 my-1" src="@/assets/img/svg/ico_points.svg" style="width:2.4em;">
          <span class="mx-auto">Plus</span>
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'articlesGrid',
  props: ['articles','no_image'],
  data () {
    return {
      showSocial: false,
      current_article: null,
      errorLoadingImageList: [],
    }
  },
  computed: {
    imageBaseURL: function() {
      return this.$_url + '/images/'
    },
  },
  components: {

  },
  created: function() {

  },
  methods: {
    getNoImage(shortname){
      try {
        if(this.no_image) return this.no_image;
        if(!shortname) throw 'no shortname';
        return require('@/assets/img/themes/'+shortname+'.jpg');
      } catch (e) {
        return require('@/assets/img/png/img_no_image.png');
      }
    },
    toStringDate(date) {
      const rtf1 = new Intl.RelativeTimeFormat(this.$i18n.locale || 'en', {
        localeMatcher: "best fit",
        numeric: "always",
        style: "long",
      });

      let oneDay = 24*60*60*1000;
      let oneHour = 60*60*1000;

      if(date) {
        let time = Date.now() - (new Date(date)).getTime();
        // 1 days max
        if(time <= oneDay) {
          let hours = Math.round(- time / oneHour);
          return "" + rtf1.format(hours, 'hour')
        } else {
          let days = Math.round(- time / oneDay);
          return "" + rtf1.format(days, 'day')
        }
      } else {
        return '';
      }
    },
    displayLanguage(language){
      switch (language) {
        case "fra":
          return this.$t('languages.fr');
        case "eng":
          return this.$t('languages.en');
        default:
          return language
      }
    },
    errorLoadingImage(uri){
      this.errorLoadingImageList.push(uri);
    },
    openArticle(article){
      if(!article.article_url) return this.$bvToast.toast('Missing article url', this.__toaster_opts('error', 'Error'))
      this.$emit('mediaRedirection', { article: article })
      window.open(article.article_url, '_blank');
    },
    saveArticle(article, index){
      this.$emit('saveArticle', {article: article, index: index})
    },
    copyURL(article){
      if(!article.article_url) return this.$bvToast.toast('Missing article url', this.__toaster_opts('error', 'Error'))
      navigator.clipboard.writeText(article.article_url);
      this.$bvToast.toast('Text Copied !', this.__toaster_opts('success', 'Success'));
    },
    shareToDevice(title, text, url){
      const shareData = {
        title: title,
        text: text,
        url: url
      }

      console.log("shareData = " + JSON.stringify(shareData));

      navigator.share(shareData)
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';

.articleContenerClass {
  width: calc(33.33% - 2em) !important; // 3 articles for each line
  margin: 1em 1em 1em 1em;
}

.articleClass {
  position: relative;
  height: 35em;

  color: black;

  background-color: white;
  border-radius: 5px;
  // margin: 0.5em 1em 0.5em 1em;
  padding: 1em;

  box-shadow: 1px 0px 20px -1px #e5e5e5;

  #clickableZone {
    cursor: pointer;
  }

  #articleImage {
    width: 100%;
    height: 14em;
    object-fit: cover;
    margin-bottom: 1em;
  }

  #articleNoImage {
    width: 100%;
    height: 14em;
    object-fit: cover;
    margin: 0 auto 1em auto;
  }

  #mediaName {
    color: $color-5;
    font-weight:bold;
    text-overflow:ellipsis !important;
    overflow: hidden;
    white-space:nowrap;
    max-width:85%;
  }

  #articleLanguage {
    font-weight: lighter;
    font-size: 0.9em;
    color: $color-1;
  }

  #title {
    font-weight: bold;
    color: $color-1;
    margin: 1em auto 1em 0;
  }

  #buttons {
    margin-top: auto;
    margin-bottom: 1em;
  }

  #bookmark {
    width: 1.5em;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
}

@media screen and (min-width: 700px) {
  .smallScreen {
    display: none !important;
  }
}

@media screen and (max-width: 1000px) {
  .articleContenerClass {
    width: calc(50% - 2em) !important; // 2 articles for each line
  }
  .articleClass {
    height: 38em;
  }

  #articleImage {
    height: 18em;
  }
}


@media screen and (max-width: 700px) {
  .articleContenerClass {
    width: calc(100% - 2em) !important; // 1 article for each line
  }
  .articleClass {
    height: 35em;

    #articleImage {
      width: 100%;
      height: 17em;
      object-fit: cover;
      margin-bottom: 1em;
    }

    #articleNoImage {
      width: 100%;
      height: 15em;
      object-fit: cover;
      margin: 0 auto 1em auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .articleClass {
    height: 35em;
  }
}

@media screen and (max-width: 400px) {
  .articleClass {
    height: 37em;
  }
}


</style>

<!--
@Author: laborde
@Date:   2018-07-09T09:45:50+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-06-23T14:21:15+02:00
-->

<template>
  <div class="">
    <div id="conteneur" class="d-flex flex-column">

      <div class="languageSwitch d-flex">
        <b-button class="flagButton p-0 mr-2 my-auto" @click="switchLanguage('en')" :title="$t('languages.en')">
          <img src="@/assets/img/svg/ico_english_flag.svg" :class="(language == 'en')? 'flagButtonEnable' : 'flagButtonDisabled'" style="height:1.3em" />
        </b-button>
        <b-button class="flagButton p-0 my-auto" @click="switchLanguage('fr')" :title="$t('languages.fr')">
          <img src="@/assets/img/svg/ico_french_flag.svg" :class="(language == 'fr')? 'flagButtonEnable' : 'flagButtonDisabled'" style="height:1.3em" />
        </b-button>
      </div>


      <div id="mytitle" class="mx-auto mt-auto mb-4">
        <span>{{ $t('password.' + type + '.t')}}</span>
      </div>

      <div class="cont mx-auto mb-auto mt-4">
        <b-form @submit.prevent="reset()">
          <div class="d-flex align-items-center mb-1" style="position:relative">
            <b-input class="myInput" :type="(displayPassword)? 'text' : 'password'" v-model="password" required :placeholder="$t('password.' + type + '.new_password')"></b-input>
            <b-button class="eyeButton transparent_button pt-1 pl-0 pr-1" @click="displayPassword = !displayPassword">
              <img v-if="!displayPassword" src="@/assets/img/png/ico_hide.png" style="opacity:0.5;height:1.5em"/>
              <img v-if="displayPassword" src="@/assets/img/png/ico_show.png" style="opacity:0.8;height:1.5em"/>
            </b-button>
          </div>

          <div id="infoBlock" class="p-3 mb-3">
            <div class="mb-1">{{ $t('password.format.p') }}</div>
            <div class="d-flex flex-column">
              <div :class="[testRule(testPassword, 1) ? 'ruleOK' : 'ruleKO']"><span class="mr-2">{{(testRule(testPassword, 1))? '✓' : '✗'}}</span>{{ $t('password.format.rules[0]') }}</div>
              <div :class="[testRule(testPassword, 2) ? 'ruleOK' : 'ruleKO']"><span class="mr-2">{{(testRule(testPassword, 2))? '✓' : '✗'}}</span>{{ $t('password.format.rules[1]') }}</div>
              <div :class="[testRule(testPassword, 3) ? 'ruleOK' : 'ruleKO']"><span class="mr-2">{{(testRule(testPassword, 3))? '✓' : '✗'}}</span>{{ $t('password.format.rules[2]') }}</div>
              <div :class="[testRule(testPassword, 4) ? 'ruleOK' : 'ruleKO']"><span class="mr-2">{{(testRule(testPassword, 4))? '✓' : '✗'}}</span>{{ $t('password.format.rules[3]') }}</div>
              <div :class="[testRule(testPassword, 5) ? 'ruleOK' : 'ruleKO']"><span class="mr-2">{{(testRule(testPassword, 5))? '✓' : '✗'}}</span>{{ $t('password.format.rules[4]') }}</div>

            </div>
          </div>

          <b-input class="myInput mb-2" :type="(displayPassword)? 'text' : 'password'" v-model="confirmPassword" required :placeholder="$t('password.' + type + '.confirm_password')" :disabled="testPassword && testPassword.length > 0"></b-input>
          <b-button class="largeColoredButton w-100" type="submit" :disabled="testPassword && testPassword.length > 0 || confirmPassword.length == 0"><span>{{ $t('btn.validate') }}</span></b-button>
        </b-form>

        <div class="d-flex mt-3">
          <b-button class="mx-auto" variant="link" @click="cancel()"><span>{{ $t('w.cancel') }}</span></b-button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

/////////////////// Vue ///////////////////

import passwordValidator from 'password-validator'

export default {
  data () {
    return {
      language: 'en',
      type: 'reset',
      password: '',
      confirmPassword: '',
      displayPassword: false,
      key: null,
      id: null,
    }
  },
  computed: {
    testPassword () {
      return (this.schema)? this.schema.validate(this.password, { list: true }) : null;
    }
  },
  created: function () {
    // this.password = 'eA1,sdcscd';
    this.language = this.$i18n.locale;

    let type = window.location.pathname.split('/').pop();
    this.type = (type == 'create')? 'create' : 'reset';

    this.key = this.$route.query.key;
    if(!this.key){
      this.$bvToast.toast('Missing authentication key', this.__toaster_opts('error', 'Error'));
      this.$router.push('/app');
    }

    this.id = this.$route.query.id;
    if(!this.id){
      this.$bvToast.toast('Missing user id', this.__toaster_opts('error', 'Error'));
      this.$router.push('/app');
    }

    let expire_on = this.$route.query.expire_on;
    if(!expire_on || parseInt(expire_on) < Date.now()){
      this.$bvToast.toast('This link has expired !', this.__toaster_opts('error', 'Error'));
      this.$router.push('/app');
    }

    this.schema = new passwordValidator();
    this.schema
    .is().min(10)
    .is().max(50)
    .has().uppercase()
    .has().lowercase()
    .has().digits(1)
    .has().letters(2)
    .has().symbols(1)
    .has().not().spaces()
  },
  methods: {
    cancel(){
      this.$router.push('/app');
    },
    switchLanguage(lang){
      // if language already good
      if(lang == this.$i18n.locale) return;
      this.language = lang;
      this.$cookies.set('language', this.language, -1);
      this.$i18n.locale = this.language
    },
    testRule(testPassword, num){
      if(!testPassword) return false;
      switch (num) {
        case 1:
          return !testPassword.includes('min') && !testPassword.includes('max');
        case 2:
          return !testPassword.includes('uppercase') && !testPassword.includes('lowercase') && !testPassword.includes('letters')
        case 3:
          return !testPassword.includes('digits')
        case 4:
          return !testPassword.includes('symbols')
        case 5:
          return !testPassword.includes('spaces')
        default:
          return false;
      }
    },
    reset () {
      let self = this;
      if(!self.password || self.password == '')  return self.$bvToast.toast("You must enter an password", self.__toaster_opts('error', 'Error'));
      if(!self.confirmPassword || self.confirmPassword == '')  return self.$bvToast.toast("You must enter an password", self.__toaster_opts('error', 'Error'));
      if(self.password != self.confirmPassword)  return self.$bvToast.toast("You must enter the same password in both fields", self.__toaster_opts('error', 'Error'));

      self.$http.post(self.$_url + '/auth/password/reset', { password: self.password, key: this.key, id: this.id }).then((response) => {
        if(self.type == 'create') {
          self.$bvToast.toast(self.$t('success.password_created'), self.__toaster_opts('success', 'Success'));
        } else {
          self.$bvToast.toast(self.$t('success.password_reset'), self.__toaster_opts('success', 'Success'));
        }
        self.$router.push('/app');
      }).catch((errors) => {
        self.$bvToast.toast(JSON.stringify(errors.body.message), self.__toaster_opts('error', 'Error'));
      })
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/css/common.scss';

#conteneur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: center;
  background-color: #f3f3f3;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cont {
  width: 30%;
  background-color: Transparent;
}

#reset_button {
  border:none;
  border-radius: 0;
  background-color:$color-1;
  font-size: 1.2em;
  color: white;
  font-family: 'Raleway-Bold';
  letter-spacing: 0.2em;
}

.myInput {
  border-radius: 0;
  border-color: #dadada;
}

.myInput::placeholder {
  color: #999999;
}

.large {
  width: 100%;
  background-color: black !important;
}

.myLink {
  // font-family: "Roboto-Regular";
  font-size: 12px;
  color: white;
  &:hover, &:active:hover, &:focus {
    color: white;
  }
}

#mytitle {
  text-align: center;
  width: 35%;
  font-family: "Raleway-Bold";
  color: $color-1;
  font-size: 36px;
}

#infoBlock {
  background-color: white;
}

.ruleOK {
  color: green;
  font-weight: 500
}

.ruleKO {
  color: red;
}

.eyeButton {
  position: absolute;
  top: 0;
  right: 0;
  // to hide password below
  background-color: white !important;
}

@media screen and (max-width: 700px) {
  .cont {
    width: 90%;
  }
}

</style>

<!--
@Author: laborde
@Date:   2018-07-09T09:45:50+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-07-06T13:38:51+02:00
-->

<template>

  <div class="">

    <div id="conteneur" class="d-flex flex-column">

      <div class="languageSwitch d-flex">
        <b-button class="flagButton p-0 mr-2 my-auto" @click="switchLanguage('en')" :title="$t('languages.en')">
          <img src="@/assets/img/svg/ico_english_flag.svg" :class="(language == 'en')? 'flagButtonEnable' : 'flagButtonDisabled'" style="height:1.3em" />
        </b-button>
        <b-button class="flagButton p-0 my-auto" @click="switchLanguage('fr')" :title="$t('languages.fr')">
          <img src="@/assets/img/svg/ico_french_flag.svg" :class="(language == 'fr')? 'flagButtonEnable' : 'flagButtonDisabled'" style="height:1.3em" />
        </b-button>
      </div>

      <img class="mt-4 mb-3 mx-auto" src="@/assets/img/png/logo.png" style="height:6em">

      <div class="cont mx-auto mb-3">
        <b-form @submit.prevent="login()">
          <b-input autocomplete="username" class="myInput mb-2" type="text" v-model="auth.username" required :placeholder="$t('inputs.username')+'/'+$t('inputs.email')"></b-input>
          <b-input autocomplete="current-password" class="myInput mb-2" type="password" v-model="auth.password" required :placeholder="$t('inputs.password')"></b-input>
          <b-form-checkbox class="mb-2" v-model="stayConnected">{{ $t('login.stay') }}</b-form-checkbox>

          <div class="d-flex align-items-center mb-2">
            <b-form-checkbox class="mr-1" v-model="trustDevice">{{ $t('login.trust') }}</b-form-checkbox>
            <img v-b-tooltip.hover.righttop="{ variant: 'info' }" :title="$t('login.trust_info')" src="@/assets/img/png/ico_info.png" class="m-0 p-0" style="width:0.8em;height:0.8em;opacity:0.5;"/>
          </div>
          <b-button id="login_button" class="large" type="submit"><span>{{ $t('btn.login') }}</span></b-button>
        </b-form>
      </div>

      <div class="d-flex mx-auto mb-auto">
        <span>{{ $t('login.not_have_account') }}</span><button class="transparent_button fontColor1" @click="createAccount()">{{ $t('login.get_one') }}</button>
      </div>

      <div align="center" class="mx-auto mt-3" style="color:red">Alpha version</div>

    </div>



    <b-modal size="md" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showCode">
      <b-button class="crossButton" @click="closeCode()">✕</b-button>

      <h3>{{ $t('ph.two_factor_auth') }}</h3>

      <b-form-select class="mb-2" v-model="auth.email" :options="emails" required :placeholder="$t('inputs.email')"/>

      <b-button v-if="twoFactoreStep == 1" class="largeColoredButton w-100 mx-auto mt-3" @click="sendCode()" :disabled="!auth.email">{{ $t('btn.sent_code') }}</b-button>

      <div v-if="twoFactoreStep == 2">
        <b-input autocomplete="one-time-code" class="myInput mb-2" type="text" v-model="auth.code" required :placeholder="$t('inputs.code')"></b-input>
        <div class="d-flex flex-column mt-3">
          <b-button id="login_button" class="large w-100 mx-auto" @click="login()">{{ $t('btn.verify') }}</b-button>
          <hr class="w-100">
          <b-button class="largeColoredButton w-100 mx-auto" @click="sendCode()">{{ $t('btn.resent_code') }}</b-button>
        </div>
      </div>


    </b-modal>


  </div>
</template>

<script>

/////////////////// Vue ///////////////////


function getUserAgent(navigator){
  let userAgent = navigator.userAgent;
  let agent = 'other';

  if((userAgent.indexOf("Opera") || userAgent.indexOf('OPR')) != -1) agent = 'opera';
  else if(userAgent.indexOf("Edg") != -1) agent = 'edge';
  else if(userAgent.indexOf("Chrome") != -1) agent = 'chrome';
  else if(userAgent.indexOf("Safari") != -1) agent = 'safari';
  else if(userAgent.indexOf("Firefox") != -1) agent = 'firefox';
  else if(userAgent.indexOf("MSIE") != -1) agent = 'explorer';

  return agent;
}

export default {
  data () {
    return {
      showCode: false,
      twoFactoreStep: 1,
      emails: [],
      auth: {
        username: null,
        email: null,
        password: null,
        code: null
      },
      user: {},
      stayConnected: false,
      trustDevice: false,
      trustedDevice: null,
      language: 'en'
    }
  },
  created: function () {
    this.trustedDevice = this.$cookies.get('trusted_device_sbapp') || null;
    if(this.trustedDevice && this.trustedDevice != '') this.trustDevice = true;
  },
  methods: {
    createAccount(){
      this.$router.push({name: 'signup'});
    },
    switchLanguage(lang){
      // if language already good
      if(lang == this.$i18n.locale) return;
      this.language = lang;
      this.$cookies.set('language', this.language, -1);
      this.$i18n.locale = this.language
    },
    sendCode () {
      let self = this;
      if(!self.auth.username || self.auth.username == '')  return self.$bvToast.toast("You must enter an username", self.__toaster_opts('error', 'Error'));
      if(!self.auth.email || self.auth.email == '')  return self.$bvToast.toast("You must enter an email", self.__toaster_opts('error', 'Error'));
      if(!self.auth.password || self.auth.password == '')  return self.$bvToast.toast("You must enter an password", self.__toaster_opts('error', 'Error'));

      self.auth.language = self.$i18n.locale || 'en';

      self.auth.stay_connected = self.stayConnected;

      self.$http.post(self.$_url + '/auth/login/sendCode', self.auth).then((response) => {
        let status = response.data.status;
        self.$bvToast.toast(self.$t('success.email_sent'), self.__toaster_opts('success', 'Success'));
        self.twoFactoreStep = 2;
        self.showCode = true;
      }).catch((errors) => {
        self.$bvToast.toast(JSON.stringify(errors.body.message), self.__toaster_opts('error', 'Error'));
      })
    },
    closeCode(){
      this.showCode = false;
      this.twoFactoreStep = 1;
      this.auth.email = null;
      this.auth.password = null;
      this.emails = [];
    },
    login () {
      let self = this;
      if(!self.auth.username || self.auth.username == '')  return self.$bvToast.toast("You must enter an username", self.__toaster_opts('error', 'Error'));
      if(!self.auth.password || self.auth.password == '')  return self.$bvToast.toast("You must enter an password", self.__toaster_opts('error', 'Error'));

      self.auth.agent = getUserAgent(navigator);

      self.auth.stay_connected = self.stayConnected;

      if(self.trustDevice) {
        if(self.trustedDevice) self.auth.trusted_device = self.trustedDevice;
        else self.auth.trust_device = true;
      } else {
        // Remove trusted device
        self.$cookies.remove('trusted_device_sbapp');
        self.trustedDevice = null;
      }

      self.$http.post(self.$_url + '/auth/login', self.auth).then((response) => {
        if(response.data.status == 'requets_code'){
          // Display popup to get secret code
          self.emails = response.data.emails;
          self.emails.unshift({value: null, text: self.$t('ph.select_email_address')})
          self.showCode = true;
          return;
        }

        // Add access token
        let expirationDate = new Date(response.data.expire_in);
        self.$cookies.set('access_token_sbapp', response.data.access_token, expirationDate);

        // Add trusted device
        if(response.data.trusted_device && response.data.trusted_device != ''){
          self.$cookies.set('trusted_device_sbapp', response.data.trusted_device, '31d');
          self.trustedDevice = response.data.trusted_device;
        }

        let nb_connection = self.$cookies.get('nb_connection') || 0;
        self.$cookies.set('nb_connection', ++nb_connection, -1)

        if(self.$route.query.to) {
          let url = self.$route.query.to;
          if(self.$route.query.query && self.$route.query != ''){
            let query = JSON.parse(self.$route.query.query);
            let keys = Object.keys(query);
            if(keys && keys.length > 0){
              let m = keys.map(a => a + '=' + query[a]).join('&');
              url = url + '?' + m
            }
          }
          self.$router.push(url);
        } else {
          if(response.data.has_premium_access) {
            // Redirect to home page
            self.$router.push({ name: 'home'});
          } else {
            // Redirect to account page
            self.$router.push({ name: 'account'});
          }
        }
      }).catch((errors) => {
        self.$bvToast.toast(JSON.stringify(errors.body.message), self.__toaster_opts('error', 'Error'));
      })
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/css/common.scss';

#conteneur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: center;
  background-color: #f3f3f3;
}

.cont {
  width: 30%;
  background-color: Transparent;
}

#login_button {
  border:none;
  border-radius: 0;
  background-color:$color-1;
  font-size: 1.2em;
  color: white;
  font-family: 'Raleway-Bold';
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.myInput {
  border-radius: 0;
  border-color: #dadada;
}

.myInput::placeholder {
  color: #999999;
}

.large {
  width: 100%;
}

@media screen and (max-width: 700px) {

  .cont {
    width: 90%;
  }

}

</style>

<!--
@Author: laborde
@Date:   2022-03-24T09:31:25+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-06-24T10:27:49+02:00
-->

<template>
  <div id="menu" class="d-flex align-items-center">

    <div class="normalScreenMenu">
      <div class="d-flex align-items-center justify-content-center ml-2">
        <b-button @click="goTo('home')" :disabled="!hasPremiumAccess" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('home') }]" tag="button">{{ $t('menu.home') }}</b-button>
        <b-button @click="goTo('favorites')" :disabled="!hasPremiumAccess" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('favorites') }]" tag="button">{{ $t('menu.favorites') }}</b-button>
        <b-button @click="goTo('themes')" :disabled="!hasPremiumAccess" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('themes') }]" tag="button">{{ $t('menu.themes') }}</b-button>
      </div>

      <div id="logo" class="d-flex" @click="goTo('home')">
        <img class="inverted" src="@/assets/img/png/logo.png" style="height:4em;cursor: pointer;">
      </div>

      <div id="logout" class="d-flex">
        <b-button @click="goTo('account')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('account') }]" tag="button">{{ $t('menu.account') }}</b-button>
        <b-button id="logoutButton" @click="logout()" class="transparent_button menu-button my-auto" tag="button">{{ $t('menu.logout') }}</b-button>
      </div>
    </div>

    <div class="smallScreenMenu">

      <img v-b-toggle.sidebar-1 class="inverted ml-2" src="@/assets/img/svg/ico_menu.svg" style="height:2em;cursor: pointer;">

      <div id="logo" class="d-flex" @click="goTo('home')">
        <img class="inverted" src="@/assets/img/png/logo.png" style="height:4em;cursor: pointer;">
      </div>

      <b-sidebar id="sidebar-1" body-class="sidebarBodyClass" width="10em" no-header backdrop shadow>

        <div class="d-flex flex-column">
          <b-button @click="goTo('home')" :disabled="!hasPremiumAccess" :class="['router','transparent_button','menu-button-left', { 'menu-button-left-active': activePage('home') }]" tag="button">{{ $t('menu.home') }}</b-button>
          <b-button @click="goTo('favorites')" :disabled="!hasPremiumAccess" :class="['router','transparent_button','menu-button-left', { 'menu-button-left-active': activePage('favorites') }]" tag="button">{{ $t('menu.favorites') }}</b-button>
          <b-button @click="goTo('themes')" :disabled="!hasPremiumAccess" :class="['router','transparent_button','menu-button-left', { 'menu-button-left-active': activePage('themes') }]" tag="button">{{ $t('menu.themes') }}</b-button>
          <b-button @click="goTo('account')" :class="['router','transparent_button','menu-button-left', { 'menu-button-left-active': activePage('account') }]" tag="button">{{ $t('menu.account') }}</b-button>
          <hr class="mb-1 mt-3" style="width:90%">
          <b-button @click="logout()" class="transparent_button menu-button-left my-auto" tag="button">{{ $t('menu.logout') }}</b-button>
        </div>

      </b-sidebar>
    </div>

  </div>


</template>

<script>

import { getUserPlan } from '@/libs/user';

export default {
  name: 'topmenu',
  data () {
    return {
      current_plan: null
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    hasPremiumAccess() {
      return this.current_plan && this.current_plan.hasPremiumAccess;
    }
  },
  created: function() {
    let self = this;
    getUserPlan(function (err, current_plan) {
      if(err) return self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      self.current_plan = current_plan;
      self.loading = false;
    });
  },
  methods: {
    activePage(name){
      return this.routeName && this.routeName == name;
    },
    goTo(name){
      if(!this.routeName || this.routeName != name) this.$router.push({ name: name })
    },
    logout () {
      window.$cookies.remove('user_data_sbapp');
      window.$cookies.remove('user_plan_sbapp');
      window.$cookies.remove('user_team_sbapp');

      window.$cookies.remove('access_token_sbapp');

      this.$router.push({ name: 'login' });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import '@/assets/css/common.scss';

#menu {
  height: 3em;
  width: 100%;
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-5;
  overflow-y: hidden;
  border: 1em;
}

.sidebarBodyClass {
  background-color: $color-5 !important;
}


.menu-button {
  color: $color-4-darker;
  font-family: 'Raleway-SemiBold';
  margin-left:0.9em;
  margin-right:0.9em;
  padding-left:0.1em;
  padding-right:0.1em;

  &:hover:enabled, &:focus:enabled {
    color: $color-4-light;
  }

}

.menu-button-active {
  color: $color-4-light;
}

.menu-button-left {
  color: $color-4-darker;
  font-family: 'Raleway-SemiBold';
  text-align: left;

  &:hover:enabled, &:focus:enabled {
    color: $color-4-light;
  }

}

.menu-button-left-active {
  color: $color-4-light;
}

#logout {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;

  #logoutButton {
    color: white !important;
  }
}

#logo {
  position: absolute;
  height: 100%;
  left: calc(50% - 54px);
  top: -0.5em;
}

@media screen and (min-width: 775px) {
  .smallScreenMenu {
    display: none !important;
  }
}

@media screen and (max-width: 775px) {
  .normalScreenMenu {
    display: none !important;
  }
}

</style>

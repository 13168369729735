<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-06-23T13:37:21+02:00
-->

<template>
  <div id="main">
    <line-loader :loading="loading"></line-loader>

    <div v-if="!loading && themes">

      <div v-if="step == 1">

        <div class="mainTitle">
          <div id="mtitle">{{ $t('w.themes') }}</div>
          <div id="stitle">{{ themes.length }} {{ $t('w.themes') }}</div>
          <hr>
        </div>

        <div class="d-flex flex-wrap">
          <div class="themeTileClass mr-3" v-for="(theme,i) in themes"  :key="theme._id">

            <div id="followButton" :class="['d-flex', 'align-items-center', { 'followedButton': theme.isFollowed }]" @click="followTheme(theme, i)" title="Follow" style="cursor:pointer;">
              <img v-if="!theme.isFollowed" class="mr-1" src="@/assets/img/png/ico_star_outlined.png">
              <img v-if="theme.isFollowed" class="mr-1" src="@/assets/img/png/ico_star_fill.png">
            </div>

            <div class="d-flex flex-column mx-auto" @click="goToTheme(theme)">
              <img v-if="!theme.__image_loading_error" id="themeImage" class="mx-auto mb-3" :src="getImage(theme.shortname)" @error="theme.__image_loading_error = true">
              <img v-else id="themeImage" class="mx-auto mb-3" src="@/assets/img/png/img_no_image.png">

              <span class="mx-auto" id="title">{{ (language == 'fr')? theme.name_fr : theme.name_en }}</span>
              <span class="mx-auto" id="title">{{ theme.articlesCount }} articles</span>
              <!-- <span class="mx-auto" id="title">{{ theme.newArticlesCount }} new articles</span> -->
            </div>

          </div>
        </div>

      </div>

      <div v-if="step == 2 && current_theme">
        <list :theme="current_theme" :themes="themes" :no_image="getImage(current_theme.shortname)" @goBack="goToThemesDisplay()"></list>
      </div>

    </div>

  </div>
</template>

<script>

////////////////// Import //////////////////

import list from './articles'

import Themes from '@/services/themes'
const themesService = new Themes();

////////////////// Functions //////////////////

function getThemesList(self, language, callback){

  themesService.getList().then(function(result) {
    self.themes = result.body.themes;

    // Alphabetic sort
		self.themes.sort((a,b) => {
			return ((language == 'fr')? (a.name_fr < b.name_fr) : (a.name_en < b.name_en))? -1 : 1;
		})

		self.themes.sort((a,b) => {
			if(a.isFollowed && !b.isFollowed) return  -1;
      else if(!a.isFollowed && b.isFollowed) return  1;
      else return  0;
		})

    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'Home',
  data () {
    return {
      loading: true,
      themes: null,
      themes: null,
      step: 1,
      current_theme: null,
      origin: null,
      language: null
    }
  },
  components: {
    list
  },
  created: function() {
    var self = this;

    this.language = this.$i18n.locale;

    getThemesList(self, this.language, function () {
      if(self.$route.query.theme_id) {
        let theme = self.themes.find(a => a._id == self.$route.query.theme_id);
        if(theme) self.goToTheme(theme);
      }
      if(self.$route.query.origin) {
        self.origin = self.$route.query.origin;
      }
      self.loading = false;

    });
  },
  methods: {
    getImage(shortname){
      try {
        let path = require('@/assets/img/themes/'+shortname+'.jpg');
        return path;
      } catch (e) {
        return require('@/assets/img/png/img_no_image.png');
      }
    },
    goToThemesDisplay(){
      if(this.origin == 'followed') {
        this.$router.push({ name: 'following', query: { tab: 'themes' } })
      } else {
        this.step = 1;
        this.current_theme = null;
      }
    },
    followTheme(theme, index){
      let self = this;
      themesService.follow(theme._id).then(function(result) {
        self.themes[index].isFollowed = result.body.isFollowed;
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    },
    goToTheme(theme){
      this.current_theme = theme;
      this.step = 2;
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';



</style>
